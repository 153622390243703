@import "./mixin.module.scss";
@import "./partials.scss";

.taskDescription {
  position: relative;
  width: 100%;
  min-height: max-content;
  max-height: 100%;
  background-color: $light-gray;
  @include allCenter;
  .taskDescription_Wrapper {
    width: 100%;
    padding: 10px;
    .taskDescription_Box {
      width: 100%;
      background-color: $white;
      padding: 20px 30px;
      overflow: hidden;
    }
    .taskDescription_Top {
      position: relative;
      width: 100%;
      @include verticallycenter;
      margin-bottom: 40px;
      .taskDescription_logo {
        position: relative;
        width: 120px;
        height: 120px;
        border-radius: 100%;
        overflow: hidden;
        box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.225);
        &:before {
          content: "";
          position: absolute;
          background-color: $primary-color;
          inset: -10px 30px;
          animation: animate 4s infinite linear;
        }
        @keyframes animate {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        &:after {
          content: "";
          position: absolute;
          inset: 5px;
          border-radius: 100%;
          z-index: 1;
        }
        .taskDescription_Image {
          position: absolute;
          border-radius: 100%;
          z-index: 5;
          inset: 2px;
          overflow: hidden;
          transition: 0.3s;
          @include allCenter;
          background-color: $primary-color;
          color: $white;
          box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.225);
          h1 {
            text-transform: uppercase;
            letter-spacing: 2px;
          }
        }
        &:hover .employeeDescription_Image {
          transform: scale(1.2);
          cursor: pointer;
        }
      }
      .taskDescription_Title {
        margin-left: 21px;
        h2 {
          font-size: 1.4rem;
          font-weight: 700;
          margin-bottom: 21px;
        }
        h4 {
          font-size: 0.9rem;
          font-weight: 400;
          margin-bottom: 6px;
          text-transform: capitalize;
          border-radius: 3px;
          background-color: $primary-color;
          color: $white;
          padding: 6px;
          letter-spacing: 1px;
          @include verticallycenter;
          svg {
            margin-right: 10px;
            font-size: 1.6rem;
          }
        }
      }
      .taskDescription_date {
        position: absolute;
        right: 60px;
        top: 40px;
        background-color: $primary-color;
        color: $white;
        padding: 10px;
        border-radius: 6px;
        animation: date 0.5s ease;
        h4 {
          font-size: 0.8rem;
        }
      }
      @keyframes date {
        0% {
          transform: translateY(-100px);
        }
        50% {
          transform: translateY(10px);
        }
        100% {
          transform: translateY(0px);
        }
      }
    }

    .taskDescription_Bottom {
      width: 100%;
      .taskDescription_detail_heading {
        margin: 16px 0px;

        h1 {
          font-size: 1.3rem;
          font-weight: 600;
          color: #444;
        }
      }
      .taskDescription_Detail_Wrapper,
      .taskDescriptionBox {
        width: 100%;
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .taskDescription_Detail {
          width: 27%;
          padding: 12px;
          @include verticallycenter;
          .taskDescription_Detail_Icon {
            margin-right: 10px;
            padding: 3px;
            svg {
              font-size: 1.4rem;
              color: #555;
            }
          }
          .taskDescription_Detail_Item {
            width: 100%;
            span {
              font-size: 1rem;
              font-weight: 600;
              color: #666;
            }
            h4 {
              color: $primary-color;
              margin: 5px 0px;
              font-size: 0.9rem;
              font-weight: 600;
            }
            p {
              width: 90%;
              color: $primary-color;
              margin: 5px 0px;
              font-size: 1rem;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

.taskDescriptionBox {
  .taskDescription_Detail {
    width: 100% !important;
    min-height: max-content;
    max-height: 500px;
    overflow: auto;
  }
}
