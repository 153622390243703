@import "./mixin.module.scss";
@import "./partials.scss";

.ticket_bottom {
    position: relative;
    width: 100%;
    background-color: $white;
    box-shadow: 0px 0px 5px 1px rgba(85, 5, 0, 0.3);

    .tabledec {
        position: absolute;
        width: 550px;
        height: 500px;
        z-index: 98;
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
        top: -60px;
        left: 220px;
        transform: scale(0) rotate(360deg);
        transition: transform 0.3s ease;

        .tabledec_wrapper {
            padding: 40px 20px;
        }

        .close {
            width: 40px;
            height: 40px;
            background-color: $white;
            position: absolute;
            right: 5px;
            top: 5px;
            border-radius: 40px;
            cursor: pointer;
            @include allCenter;

            svg {
                font-size: 2rem;
                transition: 0.3s ease;
            }

            &:hover svg {
                transform: rotate(360deg);
            }
        }

        div {
            margin: 5px;

            label {
                color: $white;
                font-size: 1.2rem;
            }

            div>input {
                color: $white;
            }
        }
    }

    .tabledecActive {
        transform: scale(1);
    }

    .tableSearch {
        @include input(8px 12px);
        width: 250px;
        border: 0.5px solid #aaa;
    }

    .tableassign {
        @include verticallycenter;
        margin: 10px 0px;

        .assignIcon {
            position: relative;
            width: 30px;
            height: 30px;
            margin-right: 10px;
            border-radius: 50%;

            img {
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }

    .prioritySelect {
        position: relative;
        width: 100px;
        height: 30px;

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            background-color: $white;
            border: 0px;
            outline: none;
            border-radius: 5px;
            padding: 0px 10px;
            text-align: center;
        }

        .option {
            position: absolute;
            top: 70px;
            width: 100%;
            // background-color:$white;
            box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            overflow: hidden;
            display: none;

            div {
                display: flex;
                align-items: center;
                transition: 0.2s ease;
                white-space: nowrap;

                &:hover {
                    background-color: $primary-color;
                    color: $white;
                }

                svg {
                    font-size: 1rem;
                    margin-right: 10px;
                }
            }
        }

        .optionActive {
            // display: block;
        }
    }

    .dec_Icon {
        @include allCenter;
        width: 100%;
        color: $primary-color;
        transition: 0.1s ease;

        svg {
            font-size: 1.7rem;
        }

        &:hover {
            color: $success-color;
            transform: scale(1.3);
            transition: 0.1s ease;
        }
    }

    .tableIcon {
        color: $primary-color;
        @include verticallycenter;
        gap: 10px;
        transition: 0.1s ease;

        svg {
            font-size: 1.7rem;
        }

        svg:nth-child(1) {
            &:hover {
                color: orange;
                transform: scale(1.1);
            }
        }

        svg:nth-child(2) {
            &:hover {
                color: $success-color;
                transform: scale(1.1);
            }
        }
    }
}

.tableHeadeer {
    @include verticallycenter;
    gap: 30px;

    .tableSelect_wrapper {
        @include verticallycenter;
        gap: 12px;

        span {
            white-space: nowrap;
            font-size: 1rem;
            color: $primary-color;
            font-weight: 600;
        }

        .tableSelect {
            box-shadow: none !important;
            border: none;
            outline: none;
            border: 0.5px solid #aaa;
            height: auto;
            padding: 8px 12px;
            font-size: 0.9rem;
            border-radius: 3px;
            font-family: GothamBook;
            width: 200px;
        }
    }

    .tableSearch {
        @include input(8px 12px);
        width: 250px;
        border: 0.5px solid #aaa;
    }
}