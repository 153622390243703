@import "./mixin.module.scss";
@import "./partials.scss";

.goBack{
    width: 100%;
    padding: 10px;
    span{
        @include verticallycenter;
        color: $primary-color;
        font-size: 1.3rem;
        font-weight: 600;
        cursor: pointer;
        animation: hello 0.3s ease;
        svg{
            margin-right: 10px;
            animation: goback 0.6s ease-in-out infinite;
        }
        @keyframes goback {
            0%{transform: translateX(-10px)}
            50%{transform: translateX(0px)}
            100%{transform: translateX(-10px)}
        }
        @keyframes hello {
            0%{transform: scale(0);}    
           100%{transform: scale(1);}
        }
    }
}