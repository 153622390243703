@import "./mixin.module.scss";
@import "./partials.scss";

.customizer {
  position: fixed;
  top: 0;
  right: -351px;
  transition: 0.3s ease;
  z-index: 101;

  .customizer_right {
    min-height: 100vh;
    max-height: 100%;
    position: relative;
    width: 350px;
    background-color: $white;
    box-shadow: 0px 0px 6px 1px rgba(85, 5, 0, 0.3);

    .customizer_btn {
      position: absolute;
      left: -47px;
      top: 40%;
      background-color: $primary-color;
      width: 45px;
      height: 45px;
      border-radius: 6px 0px 0px 6px;
      @include allCenter;
      cursor: pointer;
      border: 1px solid $primary-color;
      box-shadow: -5px 0px 6px 1px rgba(85, 5, 0, 0.3);

      svg {
        font-size: 2.3rem;
        color: $white;
        animation: customizeBtn 2s linear infinite;
      }
      @keyframes customizeBtn {
        100% {
          transform: rotate(360deg);
        }
      }
    }
    .customizer_heading {
      position: relative;
      padding: 20px;
      border-bottom: 1px solid #f5f5f5;
      h3 {
        margin-bottom: 12px;
        font-size: 1.6rem;
        font-weight: 600;
      }
      h5 {
        font-size: 0.8rem;
      }
      .customizer_CloseIcon {
        position: absolute;
        right: 26px;
        top: 26px;
        @include closeBtn;
      }
    }
    .customizer_Layout {
      padding: 12px;
      .customizer_Layout_title {
        margin-bottom: 10px;
        h5 {
          margin-bottom: 8px;
          font-size: 1.1rem;
          font-weight: 600;
        }
        h6 {
          font-size: 0.7rem;
        }
      }
      .customizer_Layout_main {
        width: 100%;
        padding: 10px 0px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 15px;

        .customizer_Layout_item {
          background-color: $white;
          @include allCenter;
          flex-direction: column;
          gap: 10px;

          .customizer_Layout_img {
            position: relative;
            border: 1px solid rgba(0, 0, 0, 0.125);
            padding: 10px;
            border-radius: 5px;
            cursor: pointer;
            @include allCenter;

            img {
              width: 75px;
            }

            .customizer_Gradient {
              position: relative;
              width: 75px;
              height: 75px;
              border-radius: 6px;
              @include allCenter;
              background: linear-gradient(
                90deg,
                #ff0000,
                #764ba2,
                #ff0000,
                $primary-color,
                #764ba2,
                #ff0000
              );
              background-size: 400%;
              &:hover {
                animation: gradientColor 12s linear infinite;
              }
              @keyframes gradientColor {
                0% {
                  background-position: 0%;
                }
                100% {
                  background-position: 400%;
                }
              }
              svg {
                font-size: 2rem;
                color: $white;
                animation: color 3s ease infinite;

                @keyframes color {
                  0% {
                    transform: translateX(6px);
                  }
                  25% {
                    transform: translateY(6px);
                  }
                  50% {
                    transform: translateX(-6px);
                    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
                  }
                  75% {
                    transform: translateY(-6px);
                  }
                  100% {
                    transform: translateX(6px);
                  }
                }
              }
              @media screen and (max-width: 450px) {
                width: 40px;
                height: 40px;
                svg {
                  font-size: 1.3rem;
                }
              }
            }
            @media screen and (max-width: 450px) {
              img {
                width: 40px;
              }
            }
          }
          @media screen and (max-width: 450px) {
            .customizer_Layout_dec {
              h5 {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
    .colorPicker {
      position: absolute;
      width: 300px;
      height: 300px;
      background-color: $white;
      right: -350px;
      top: 170px;
      transition: 0.3s ease;
      border-radius: 12px 0px 0px 12px;
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.225);
      padding: 10px;
      text-align: center;
      .colorPickerTop {
        position: relative;
        padding: 6px;
        @include allCenter;
        h2 {
          font-size: 1.3rem;
        }
        .colorPickerClose {
          position: absolute;
          @include closeBtn;
          top: 0;
          left: 10px;
          @media screen and (max-width: 450px) {
            left: 0px;
          }
        }
      }
      @media screen and (max-width: 450px) {
        width: 200px;
        .colorPickerClose {
          left: 0px !important;
        }
      }
    }
    .colorPickerActive {
      right: 0px;
    }
    @media screen and (max-width: 450px) {
      width: 250px;
    }
  }
  @media screen and (max-width: 450px) {
    right: -250px;
  }
}

// Active
.customizerActive {
  right: 0px;
  transition: 0.3s ease;
}

.Activehe {
  border: 2px solid $success-color !important;
}
.LayoutActive {
  position: absolute;
  color: $primary-color;
  font-size: 1.5rem;
}
