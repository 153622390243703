@import "./mixin.module.scss";
@import "./partials.scss";

// MAIN DIV CONTAINER
.container {
  width: 100%;

  // MAIN GRID CONTAINER
  .gridcon {
    @include allCenter;

    //    MAIN GRID ITEM
    .griditem {
      @include allCenter;
      background-color: white;
      flex-direction: column;
      border: 1px solid lightgray;
      margin-top: 10px;

      // PROFILE IMAGE CONTAIN

      .profileitem {
        position: relative;
        width: 120px;
        height: 120px;
        margin: 20px 0px;
        border-radius: 100%;
        @include allCenter;
        background-color: $primary-color;
        color: $white;
        box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.225);

        h1 {
          text-transform: uppercase;
          letter-spacing: 2px;
          border-radius: 100%;
          width: 100px;
          height: 100px;
          @include allCenter;
          border: 3px solid $white;
        }
      }

      //  FISRT NAME , LAST NAME CONATINER
      .namecon {
        @include allCenter;
        margin: 0.5rem 0;

        .nameitem {
          padding: 0 1rem;

          label {
            font-size: 1.8rem;
          }

          Input {
            margin: 0.5rem 0 !important;
          }
        }
      }

      // SKILLS CONTAINER

      .skillcon {
        @include allCenter;
        margin: 0.5rem 0;

        .skillitem {
          padding: 0rem 1rem;

          label {
            font-size: 1.8rem;
          }

          .stack {
            color: white;
            width: 100%;
            @include allCenter;
            flex-wrap: wrap;
          }

          .stack_icon {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 10px 0px;
          }

          .chips {
            background-color: $primary-color;
            color: white;
            font-size: 1.2rem;
            text-transform: capitalize;

            margin: 0.5rem 0.5rem;
          }

          .addskills {
            font-size: 3rem;
            color: $primary-color;
            cursor: pointer;
          }
        }
      }

      //  EMAIL, PHONE CONATINER
      .emailcon {
        @include allCenter;
        margin: 0.5rem 0;

        .emailitem,
        .phoneitem {
          padding: 0 1rem;

          label {
            font-size: 1.8rem;
          }

          Input {
            margin: 0.5rem 0 !important;
          }
        }
      }

      //  NIC CONATINER
      .niccon {
        @include allCenter;
        margin: 0.5rem 0;

        .nicitem,
        .genderitem {
          padding: 0 1rem;

          label {
            font-size: 1.3rem;
          }

          Input {
            margin: 0.5rem 0 !important;
          }

          div {
            margin: .5rem 0;

            .genderInput {
              margin: 0 !important;
            }
          }

        }
      }

      //  ADDRESS CONATINER
      .addresscon {
        @include allCenter;
        margin: 0.5rem 0;

        .addressitem {
          padding: 0 1rem;

          label {
            font-size: 1.8rem;
          }

          Input {
            margin: 0.5rem 0 !important;
          }
        }
      }

      //  COUNTRY , CITY CONATINER

      .countrycon {
        @include allCenter;
        margin: 0.5rem 0;

        .countryitem {
          padding: 0 1rem;

          label {
            font-size: 1.8rem;
          }

          div {
            margin: .5rem 0;

            .cityinput {
              margin: 0 !important;
            }
          }
        }

        .birthitem {
          padding: 0 1rem;
          position: relative;

          label {
            font-size: 1.8rem;
          }

          // .calendarDiv {
          //   margin: 1rem 0;
          //   position: absolute;
          //   padding: 0;
          //   z-index: 1;
          //   margin: 0;

          //   .calendar {
          //     border: 1px solid #283686;
          //     margin: 0;
          //     padding: 0;
          //   }
          // }
          .calendar_div {
            width: 100%;

            // margin-top: 10px;

            .calendar {
              border: none;
              height: 40px;
              display: block;
              width: 99%;

              margin: 8px 0px;
              color: grey;
              box-shadow: -4.973px 0.523px 5.82px 0.18px rgba(2, 29, 59, 0.11);
              border-width: 0.81px;
              border-color: rgb(189, 186, 186);
              border-style: solid;
              border-radius: 5px;
              background-color: rgb(255, 255, 255);
              text-indent: 10px;
              font-size: 1.2rem;

              &::placeholder {
                text-transform: capitalize;
              }

              &:focus {
                outline: none;
              }
            }
          }
        }
      }

      //  BIRTH , MATERIAL STATUS CONATINER

      .marital_altnum {
        @include allCenter;
        margin: 0.5rem 0;

        .maritalitem {
          padding: 0 1rem;

          label {
            font-size: 1.8rem;
          }

          .marital {
            margin: .5rem 0;

            input {
              margin: 0.5rem 0;
            }
          }
        }

        .altNumitem {
          padding: 0 1rem;

          label {
            font-size: 1.8rem;
          }

          input {
            margin: 0.5rem 0;
          }
        }
      }

      .btncon {
        @include allCenter;
        margin: 0.5rem 0;

        .btnitem {
          @include verticallycenter;

          white-space: nowrap;

          Button {
            @include button(5px 17px, 17px);
            background-color: $success-color;
            font-size: 0.9rem;
            transition: 0.1s ease;
            font-family: GothamBook;
            font-weight: 600;
            margin: 0 20px;

            &:disabled {
              // @include button(5px 17px, 17px);
              background-color: $light-gray-color;
              // padding: 10px 50px 10px 40px;
              // text-align: center !important;
              // vertical-align: middle !important;
            }

            &:hover {
              background-color: $primary-color;
            }

            .loader {
              width: 23px !important;
              height: 23px !important;
              padding: 1px 45px;
              // margin-left: 10px;
            }
          }

          Button:nth-child(2) {
            background-color: $light-gray-color;

            &:hover {
              background-color: $red;
            }
          }
        }
      }
    }
  }
}

// Skill Box
.skillInput,
.skillInputs {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  input {
    position: relative;
    width: 100%;
    @include input(10px 40px 10px 6px);
  }

  svg {
    position: absolute;
    right: 5px;
    font-size: 1.8rem;
    cursor: pointer;
    color: $primary-color;
    transition: 0.2s ease;

    &:hover {
      color: red;
    }
  }
}

.skillInputs {
  .mainInput {
    color: #111;
    font-weight: 600;
    border: 1.5px solid $primary-color;
    font-size: 1rem;
    margin: 10px 0px;
    transform: scale(1);
  }
}

.skillInputsActive {
  .mainInput {
    transform: scale(0);
    transition: 0.1s ease;
  }

  svg {
    transform: scale(0);
    transition: 0.1s ease;
  }
}

.skillbtn {
  padding: 5px 24px !important;
  justify-content: space-between !important;
  text-transform: capitalize;
  margin-bottom: 10px;

  button {
    @include button(8px 20px, 2px);
    @include verticallycenter;
    border: 0.5px solid $white;
    transition: 0.1s ease;

    svg {
      font-size: 1rem;
      margin-left: 6px;
    }

    &:hover {
      background-color: $white;
      color: $primary-color;
      border: 0.5px solid $primary-color;
      font-weight: 600;
    }
  }
}

//
.nameitem,
.skillitem,
.emailitem,
.phoneitem,
.nicitem,
.addressitem,
.countryitem,
.birthitem,
.maritalitem,
.altNumitem {
  label {
    font-size: 1.3rem !important;
    text-transform: capitalize !important;
    white-space: nowrap;
  }

  input {
    font-size: 1.2rem;
  }

  select {
    font-size: 1.2rem;
  }
}

.skillboxText {
  position: relative;

  .skillBoxClose {
    position: absolute;
    right: 20px;
    top: 15px;
    @include closeBtn;
    width: 25px;
    height: 25px;

    svg {
      font-size: 1.5rem;
    }
  }
}

.skillDialogBox {
  overflow-y: auto;
  max-height: 300px;
}