.appContainer {
    max-width: 1180px;
    margin: 2rem auto;
    padding: 0 2rem;
}


// ================================= Rich Text Editor


form {
    select {
        option {
            // background-color: aqua !important;

        }


    }
}