@import "./mixin.module.scss";
@import "./partials.scss";

.main_container {
  position: relative;
  min-width: 100%;
  max-width: 100%;
  min-height: 100vh;
  max-height: 100%;
  background-color: $light-gray;
}

.sidebar {
  position: fixed;
  width: 280px;
  top: 0;
  left: 0;
  background-color: $light-gray;
  height: 100%;
  transition: 0.5s;
  padding: 12px 10px 0px 10px;
  z-index: 99;

  .sidebar_wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: $primary-color;
    border-radius: 10px 10px 0px 0px;

    .sidebar_task {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 20;
      border-radius: 10px 10px 0px 0px;
      background-color: $primary-color;
      // overflow: hidden;
      transform: scale(0);
      transition: 0.1s;
      transform-origin: left;
      .sidebar_task_wrapper {
        .sidebar_Task_Top {
          font-weight: 600;
          background-color: rgba(0, 0, 0, 0.325);
          padding: 26px 0px;
          transition: background-color 0.1s;
          color: $white;
          cursor: pointer;
          span {
            @include allCenter;
            margin-right: 16px;
            white-space: nowrap;
            svg {
              margin-right: 10px;
            }
          }
          &:hover {
            background-color: rgba(225, 225, 225, 0.325);
          }
        }
        .sidebar_Task_menu {
          padding: 10px 0px;
          min-height: max-content;
          max-height: 550px;
          overflow: auto;

          ul {
            list-style-type: none;
            li {
              margin: 10px 0px;
              padding: 10px 20px;
              font-weight: 600;
              color: $white;
              @include verticallycenter;
              justify-content: space-between;
              cursor: pointer;
              transition: background-color 0.2s;
              white-space: nowrap;

              &:hover {
                background: rgb(45, 51, 89);
                border-left: 3px solid $white;
              }
              &:hover svg {
                transform: rotate(360deg);
              }
              svg {
                color: orange;
                font-size: 1.8rem;
                transition: 0.2s;
              }
            }
            .Activehe {
              background: rgb(45, 51, 89);
              border-left: 3px solid $white;
            }
          }
        }
      }
    }
    .sidebar_taskActive {
      transform: scale(1);
      transition: 0.1s;
    }

    // SideBar Top

    .sidebartop {
      position: relative;
      display: flex;
      align-items: center;
      padding: 20px 5px 20px 20px;
      .horizontalclose {
        display: none;
      }
      .closelogo {
        position: absolute;
        right: 20px;
        top: 26px;
        @include closeBtn;
        @include allCenter;
        display: none;

        @media screen and (max-width: 890px) {
          display: block;
        }
      }

      .sidebarLogo {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 100%;
        height: 100%;
        white-space: nowrap;
      }

      span {
        font-size: 1.2rem;
        font-weight: 600;
        color: $white;
        white-space: nowrap;
        text-transform: capitalize;
      }
    }

    // SideBar Bottom

    .sidebarbottom {
      display: flex;
      flex-direction: column;
      color: white;

      .sidebarmenu {
        list-style: none;

        a {
          margin: 2px 0px;
          padding: 6px 12px;
          display: flex;
          justify-content: space-between;
          transition: background-color 0.2s;
          white-space: nowrap;

          &:hover {
            background: rgb(45, 51, 89);
            border-left: 3px solid $white;
          }

          li {
            @include verticallycenter;
            text-decoration: none;
            color: $white;
          }

          .sidebarIcon {
            margin: 0px 10px;

            svg {
              font-size: 1.8rem;
            }
          }

          .sidebarText {
            font-size: 1rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    left: -300px;
    padding: 12px 3px 0px 10px;
  }
  @media screen and (max-width: 400px) {
    width: 250px;
  }
}

.main {
  position: absolute;
  width: calc(100% - 300px);
  left: 300px;
  transition: 0.5s;
  background-color: $light-gray;
  overflow: hidden;
}

.Navbar {
  width: 100%;
  background-color: $white;
  padding: 20px;
  cursor: pointer;
}

/* Active */
.sidebarActive {
  width: 80px;

  .sidebartop {
    padding: 20px 0px 20px 15px !important;
    display: block !important;
  }

  .sidebartop > span {
    transform: 0.2s;
    display: none;
  }

  .sidebarIcon {
    margin: 0px 10px;

    svg {
      font-size: 1.8rem;
    }
  }

  .sidebarmenu {
    .sidebarText {
      position: absolute;
      background-color: $primary-color;
      padding: 6px;
      border-radius: 5px;
      left: 103%;
      transform: scale(0) translateX(-20px);
    }

    a:hover .sidebarText {
      transform: scale(1) translateX(0px);
      transition: 0.1s ease;
    }
  }

  @media screen and (max-width: 900px) {
    left: 0px;
    width: 280px;

    .sidebartop > span {
      display: block;
    }

    .sidebartop {
      padding: 20px 5px 20px 20px;
      display: flex !important;
    }

    .sidebarText {
      transform: scale(1) translateX(0px) !important;
      position: relative !important;
      background-color: transparent !important;
      padding: 6px !important;
      left: 0 !important;
    }

    .sidebarIcon svg {
      font-size: 2rem;
    }

    .sidebarmenu > li {
      padding: 0px;
    }

    .dropdownIcon {
      display: block;
    }
  }
}

.mainActive {
  position: relative;
  width: calc(100% - 100px);
  left: 100px;
}

.navbarActive {
  background: rgb(45, 51, 89);
  border-left: 3px solid $white;
}

@media screen and (max-width: 900px) {
  .main {
    width: 100%;
    left: 0;
  }

  .mainActive {
    left: 0px !important;
  }

  .taskProfile > h6 {
    display: block;
  }
}

// Horizontal
.sidebarhorizontal {
  width: 100%;
  background-color: $primary-color;
  height: 50px;
  padding: 12px;

  box-shadow: 0px 10px 6px 1px rgba(0, 0, 0, 0.125);
  .sidebar_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sidebar_task {
      @include allCenter;
      padding-bottom: 12px;
      .sidebar_task_wrapper {
        @include verticallycenter;
        width: 100%;
        gap: 120px;
        .sidebar_Task_Top {
          flex: 1;
        }
        .sidebar_Task_menu {
          flex: 3.5;
          ul {
            display: flex;
            li {
              padding: 23px 20px;
              &:hover {
                background: none;
                border-left: none;
                border-bottom: 2px solid $white;
              }
            }
            .Activehe {
              background: none;
              border-left: none;
              border-bottom: 2px solid $white;
            }
          }
        }
      }
    
      
    }

    .sidebartop {
      display: flex;
      .horizontalclose {
        display: none;
      }
      .closelogo {
        display: none;
      }
    }
    .sidebarbottom {
      display: flex;
      flex-direction: row;
      margin-right: 100px;
      margin-left: 20px;
      .sidebarmenu {
        a {
          position: relative;
          ::before {
            content: "";
            position: absolute;
            width: 90%;
            height: 3px;
            border-radius: 50px;
            background-color: $white;
            left: 15px;
            bottom: -15px;
            transition: 0.3s ease;
            transform: scaleX(0);
            transform-origin: left;
          }
          &:hover {
            background: none;
            border: none;
          }
          &:hover {
            ::before {
              transform: scaleX(1);
            }
          }
        }
        .navbarActive {
          position: relative;
          background: none;
          border: none;
        }
        .navbarActive::before {
          content: "";
          position: absolute;
          width: 90%;
          height: 3px;
          border-radius: 50px;
          background-color: $white;
          left: 15px;
          bottom: -15px;
        }
      }
      @media screen and (max-width: 950px) {
        position: absolute;
        left: -12px;
        top: -350px;
        z-index: 90;
        transition: 0.3s;
        width: 100%;
        flex-direction: column;
        background-color: $primary-color;
        @include allCenter;
        margin-left: 0px;
        margin-right: 0px;
        .sidebarmenu {
          a {
            margin: 10px 0px;
            ::before {
              bottom: 0px;
            }
          }
          .navbarActive::before {
            bottom: 0px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    left: 0px;
  }
  @media screen and (max-width: 950px) {
    .sidebartop {
      width: 100%;
      // z-index: 99;
      .horizontalclose {
        display: block !important;
        position: absolute;
        top: 40px;
        right: 30px;

        div {
          background-color: white;
        }
      }
    }
  }
}
.mainhorizontal {
  width: 100%;
  left: 0;
}
.sidebar_wrapperActive {
  .sidebarbottom {
    top: 62px !important;
  }
}

// hidden

.sidebarhidden {
  left: -300px;
  padding: 12px 3px 0px 10px;
  .sidebar_wrapper {
    .sidebartop {
      .closelogo {
        display: none;
      }
    }
  }
}
.mainhidden {
  width: 100%;
  left: 0;
}

.sidebarhiddenbtn {
  left: 0px;
}
.hiddenClose {
  position: absolute;
  right: 20px;
  top: 26px;
  @include closeBtn;
}

// Task 
.handburgerMenuTask {
  display: none;
}
.handburgerMenuTaskActive{
  display: block;
  background-color: yellow;
}
.sidebar_Task_menuActive{
  width: 100%;
}
.sidebar_Task_menuActive{
  position: absolute;
  width: 100%;
  min-height: max-content;
  max-height: 400px !important;
  left: -12px;
  top: -450px;
  // top:60px;
  z-index: 90;
  transition: 0.3s;
  width: 100%;
  background-color: $primary-color;
  @include allCenter;
  
  ul{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 100px;
  }
}
.sidebar_Task_TopActive{
  max-width: 200px !important;
}