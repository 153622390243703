@import "./mixin.module.scss";
@import "./partials.scss";

.tickets {
  width: 100%;
  max-height: 100%;
  background-color: $light-gray;

  .ticket_wrapper {
    padding: 10px;

    .ticket_top {
      width: 100%;
      @include horizontallysapce;
      margin-bottom: 30px;

      .ticket_left {
        h1 {
          font-size: 2rem;
          font-weight: 600;
          margin-bottom: 10px;
          color: $primary-color;
        }

        h5 {
          font-size: 1rem;
          font-weight: 400;

          a {
            font-weight: 600;
            cursor: pointer;
          }
        }

        @media screen and (max-width: 400px) {
          h1 {
            font-size: 1.6rem;
          }

          h5 {
            font-size: 0.9rem;
          }
        }
      }

      .ticket_right {
        a {
          @include button(10px 20px, 30px);
          @include allCenter;
          font-weight: bolder;
          letter-spacing: 0.5px;

          span {
            margin-right: 5px;
          }
        }

        @media screen and (max-width: 400px) {
          a {
            padding: 6px 12px;
          }
        }
      }
    }

    .ticket_boxs {
      width: 100%;
      @include verticallycenter;
      white-space: nowrap;
      gap: 10px;
      flex-wrap: wrap;
      margin-bottom: 30px;

      .ticket_box {
        flex: 1;
        background-color: $white;
        padding: 20px 15px;
        box-shadow: 0px 0px 5px 1px rgba(85, 5, 0, 0.3);

        .ticket_box_dec {
          @include horizontallysapce;
          margin-bottom: 20px;

          h3 {
            font-size: 1rem;
            padding-right: 10px;
          }

          span {
            color: $success-color;
            font-weight: 600;
            padding-left: 10px;
          }
        }

        @media screen and (max-width: 300px) {
          white-space: normal;
        }

        .ticket_box_value {
          margin-bottom: 26px;

          span {
            font-size: 1.2rem;
            font-weight: 600;
          }
        }
      }

      .ticket_box_bar {
        position: relative;
        width: 100%;
        background-color: #eee;
        height: 4px;
        border-radius: 10px;

        div {
          position: absolute;
          left: 0;
          height: 100%;
          border-radius: 10px;
          background-color: $orange-color;
          transition: 0.3s linear;
        }
      }
    }

    .ticket_bottom {
      width: 100%;
      background-color: $white;
      box-shadow: 0px 0px 5px 1px rgba(85, 5, 0, 0.3);

      .tableSearch {
        @include input(8px 12px);
        width: 250px;
      }

      .dec_Icon {
        @include allCenter;
        width: 100%;
        color: $primary-color;
        transition: 0.1s ease;

        &:hover {
          color: $success-color;
          transform: scale(1.3);
          transition: 0.1s ease;
        }
      }

      .tableIcon {
        color: $primary-color;
        @include verticallycenter;
        gap: 10px;
        transition: 0.1s ease;

        svg:nth-child(1) {
          &:hover {
            color: $success-color;
            transform: scale(1.1);
          }
        }

        svg:nth-child(2) {
          &:hover {
            color: $success-color;
            transform: scale(1.1);
          }
        }
      }
    }
  }
}