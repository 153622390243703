.input {
  border: none;
  height: 40px;
  display: block;
  width: 100%;
  margin: 5px 0px;
  color: #444;
  box-shadow: -4.973px 0.523px 5.82px 0.18px rgba(2, 29, 59, 0.11);
  border-width: 0.81px;
  border-color: rgb(189, 186, 186);
  border-style: solid;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  text-indent: 10px;
  font-size: 1.2rem;

  &::placeholder {
    text-transform: capitalize;
  }

  &:focus {
    outline: none;
  }
}
.inputError {
  color: red;
  font-size: 0.9rem;
}
.inputActive {
  border-width: 1px 10px 1px 1px !important;
  border-style: solid;
  border-color: rgb(219, 18, 88) rgb(191, 22, 80) rgb(191, 22, 80)
    rgb(236, 89, 144) !important ;
  background: rgb(251, 236, 236);
}
