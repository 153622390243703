@import "./mixin.module.scss";
@import "./partials.scss";

.employeesBoxs {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .employeesBoxSearch {
    width: 100%;
    padding: 10px 5px;
    @include allCenter;

    input {
      width: 90%;
      @include input(10px 12px);
      border: 0.5px solid #aaa;
      margin-bottom: 20px;
    }
  }
  .employeesBoxs_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .employeesBox {
      width: 250px;
      position: relative;
      background-color: $white;
      box-shadow: 0px 1px 1px 0px #aaa;
      border: 1px solid #ededed;
      margin: 0px 5px;
      margin-bottom: 40px;
      padding: 20px;
      text-align: center;
      border-radius: 4px;
      @include allCenter;
      flex-direction: column;
      overflow: hidden;
      @media screen and (max-width: 620px) {
        width: 100%;
      }

      .employeesboxMenu {
        position: absolute;
        width: 50px;
        height: 100%;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: end;
        flex-direction: column;
        gap: 6px;
        .employeesboxSubMenu {
          width: 36px;
          height: 36px;
          background-color: $white;
          box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.225);
          border-radius: 3px 0px 0px 3px;
          @include allCenter;
          cursor: pointer;
          svg {
            font-size: 1.5rem;
            color: $primary-color;
          }
        }
        .employeesboxSubMenu:nth-child(1) {
          margin-top: 60px;
          svg {
            color: $primary-color;
            &:hover {
              transform: scale(1.2);
            }
          }
        }
        // .employeesboxSubMenu:nth-child(2) {
        //   // display: none;
        //   svg {
        //     color: $red;
        //     &:hover {
        //       transform: scale(1.2);
        //     }
        //   }
        // }
        .employeesboxSubMenu:nth-child(2) {
          svg {
            color: $success-color;

            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }

      .employeesBox_img {
        position: relative;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: 10px;
        border: 1px solid #333;
        @include allCenter;
        background-color: $primary-color;
        color: $white;
        box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.225);
        h1 {
          text-transform: uppercase;
          letter-spacing: 2px;
          border: 3px solid $white;
          border-radius: 100%;
          width: 80px;
          height: 80px;
          @include allCenter;
        }
      }

      .employeesBox_detail {
        color: #333333;
        h3 {
          font-size: 1.1rem;
          margin-bottom: 6px;
        }
        h4 {
          color: #8e8e8e;
          font-size: 14px;
          margin-bottom: 10px;
        }
        h2 {
          font-size: 0.9rem;
          margin-bottom: 10px;
        }
        h6 {
          font-size: 0.7rem;
        }
      }
    }
  }
}
