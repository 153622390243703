@import "./partials.scss";

.react-datepicker__header {
    background-color: $primary-color;
    color: white;

    .react-datepicker__month-read-view {
        .react-datepicker__month-read-view--selected-month {
            margin-right: 30px !important;
        }

        .react-datepicker__month-read-view--down-arrow {
            margin-right: 30px;
        }
    }

    .react-datepicker__current-month {
        color: white;
    }

    .react-datepicker__day-names {
        .react-datepicker__day-name {
            color: white;
        }
    }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: $primary-color  !important;
}

// styling for time
.react-datepicker-time__header {
    color: white;
}

.datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown,
.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {

    background-color: $primary-color  !important;
    color: white !important;
}

element.style {}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    transform: rotate(135deg);
    right: -16px;
    top: 0;
}

.react-datepicker__year-read-view--down-arrow {

    height: 6px;
    width: 6px;
}

.react-datepicker__year-read-view--selected-year,
.react-datepicker__year-read-view--down-arrow {
    margin-top: 5px !important;
    // padding-bottom: 10px !important;
    // check year arrow not showing also align arrow and year  
}

.react-datepicker__navigation--years {
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    padding: 0;
    border: 0.45rem solid transparent;
    z-index: 1;
    height: 1px;
    width: 1px;
    padding: 5px 0;
    text-indent: -999em;
    overflow: hidden;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
    top: -5px;
    border-bottom-color: #ccc;
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
    top: 5px;
    border-top-color: #ccc;
}