@import "./mixin.module.scss";
@import "./partials.scss";

.signup {
  @include verticallycenter;
  width: 90%;

  .signup_left {
    flex: 1;
    text-align: center;
    color: $white;
    @include allCenter;
    flex-direction: column;

    h1 {
      margin-bottom: 10px;
      font-weight: 900;
      text-transform: uppercase;
      font-size: 2.3rem;
    }

    h4 {
      font-weight: 200;
      color: #ddd;
      font-size: 1.3rem;
      line-height: 1.2;
    }

    @media screen and (max-width: 850px) {
      margin-top: 50px;
      padding: 20px;
    }

    @media screen and (max-width: 450px) {
      h1 {
        font-size: 2rem;
      }

      h4 {
        font-size: 1.1rem;
      }
    }
  }

  .signup_right {
    flex: 3;
    background-color: $bg-light-gray;
    box-shadow: 0px 0px 4px #333;
    border-radius: 12px;
    margin-left: 50px;
    padding: 50px 10px;
    @include allCenter;
    flex-direction: column;

    .signup_top {
      text-align: center;
      font-size: 0.7rem;
      color: $gray;
      margin-bottom: 30px;
      margin-top: 40px;

      h2 {
        font-size: 22px;
      }

      @media screen and (max-width: 450px) {
        h2 {
          font-size: 18px;
        }
      }
    }

    .signup_bottom {
      width: 80%;

      @media screen and (max-width: 700px) {
        width: 90%;
      }
    }

    .signup_bottom>div>div>div {
      background-color: #fff;
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    }

    @media screen and (max-width: 850px) {
      margin-left: 0px;
      width: 100%;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 450px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 850px) {
    flex-direction: column !important;
    gap: 30px;
  }
}

.inputerror {
  flex-direction: column;
  width: 50%;

  input {
    font-size: 1.1rem;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.inputerrors {
  flex-direction: column;
  width: 100%;

  input {
    font-size: 1.1rem;
  }
}

.button {
  .loader {
    width: 23px !important;
    height: 23px !important;
    padding: 1px 0;
  }
}

.signupSelect {
  width: 50%;

  select {
    margin: 5px 0px;
    font-size: 1.1rem !important;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
}