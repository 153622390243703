@import "./mixin.module.scss";
@import "./partials.scss";

// Notification
.notification {
  .notification_wrapper {
    padding: 15px 10px 5px 10px;
  }
  .notification_heading {
    @include verticallycenter;
    justify-content: space-between;
    margin-bottom: 10px;
    h1 {
      font-size: 1.6rem;
      font-weight: 600;
    }
    span {
      svg {
        color: #666;
        font-size: 1.8rem;
      }
      padding: 1px;
      cursor: pointer;
      border-radius: 100%;
      transition: 0.1s;
      &:hover {
        background-color: rgba(0, 0, 0, 0.125);
      }
    }
  }
  .notification_action {
    display: flex;
    gap: 16px;
    margin-bottom: 15px;
    span {
      padding: 8px;
      font-size: 1rem;
      cursor: pointer;
      font-weight: 500;
      border-radius: 16px;
      transition: 0.1s;
      &:hover {
        background-color: rgba(0, 0, 0, 0.125);
      }
    }
    span:nth-child(1) {
      background-color: rgba(0, 0, 0, 0.125);
    }
  }
  .notification_subHeading {
    @include verticallycenter;
    justify-content: space-between;
    margin-bottom: 3px;
    h3 {
      font-size: 1.1rem;
      color: #222;
    }
    span {
      color: $primary-color;
      cursor: pointer;
      font-size: 1rem;
      transition: 0.1s;
      padding: 6px;
      &:hover {
        background-color: rgba(0, 0, 0, 0.125);
      }
    }
  }
  .notification_menu {
    width: 100%;
    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
      li {
        padding: 5px 0px;
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;
        transition: 0.1s ease;
        &:hover {
          background-color: rgba(0, 0, 0, 0.125);
        }
        .notification_image {
          width: 100px;
          @include allCenter;
          div {
            width: 60px;
            height: 60px;
            background-color: $primary-color;
            border-radius: 100%;
            @include allCenter;
            span {
              text-transform: uppercase;
              color: $white;
              font-size: 1.6rem;
              font-weight: 600;
              border-radius: 100%;
            }
          }
        }

        .notification_detail {
          width: 200px;
          p {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            margin-bottom: 3px;
            overflow: hidden;
            font-size: 1rem;
            span {
              font-weight: 600;
              margin: 0px 5px;
              color: #111;
              font-size: 1rem;
              font-family: system-ui;
            }
          }
          span {
            font-size: 0.9rem;
            color: $primary-color;
          }
        }
        .notification_done {
          width: 50px;
          @include allCenter;
          span {
            svg {
              color: $primary-color;
              font-weight: 600;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}

.notificationMenu {
  position: absolute;
  top: 43px;
  left: -240px;
  width: 350px;
  min-height: max-content;
  max-height: 450px;
  overflow: auto;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.225);
  background-color: #fff;
  border-radius: 3px;
  transition: 0.1s ease;
  transform-origin: top right;
  transform: scale(0);
}
.notification_Main {
  width: 100%;
  .notificationMain {
    height: 100%;
    padding: 20px 40px;
    .notification_wrapper {
    background-color: #fff;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.325);

      .notification_subHeading {
        margin-bottom: 10px;
      }
      .notification_menu {
        ul {
          li {
            display: flex;
            align-items: center;
          padding: 10px 20px;

            .notification_image {
              width: 10%;
              margin-right: 20px;
              div {
                width: 100px;
                height: 100px;
                span {
                  padding: 26px;
                  font-size: 2rem;
                }
              }
            }
            .notification_detail {
              width: 80%;
            }
            .notification_done {
              width: 10%;
            }
          }
        }
      }
    }
  }
}

// notificationActive
.notificationActive {
  transform: scale(1);
}
