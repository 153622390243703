@import "./mixin.module.scss";
@import "./partials.scss";

.login {
  width: 100%;

  .login_top {
    padding: 30px;
    @include allCenter;
    flex-direction: column;

    h1,
    h2 {
      color: $white;
    }

    h1 {
      font-weight: 500;
      margin-bottom: 12px;
    }

    h2 {
      font-weight: 300;
    }

    @media screen and (max-width: 760px) {
      margin-top: 50px;
    }

    @media screen and (max-width: 450px) {
      padding: 30px 0px 50px 0px;
      text-align: center;

      h1 {
        font-size: 1.7rem;
      }

      h2 {
        font-size: 1.2rem;
      }
    }

    @media screen and (max-width: 350px) {
      h1 {
        font-size: 1.4rem;
      }

      h2 {
        font-size: 1rem;
      }
    }
  }

  .login_bottom {
    height: 400px;
    @include allCenter;
    padding: 40px;

    .login_main {
      position: relative;
      width: 500px;
      background-color: $white;
      border-radius: 12px;
      height: 100%;

      .login_logo {
        position: absolute;
        top: -50px;
        left: 36%;
        width: 90px;
        height: 90px;
        background-color: $white;
        border-radius: 50%;
        padding: 25px;

        img {
          width: 100%;
          height: 100%;
        }

        @media screen and (max-width: 600px) {
          left: 34%;
        }

        @media screen and (max-width: 500px) {
          left: 32%;
        }

        @media screen and (max-width: 450px) {
          width: 80px;
          height: 80px;
        }

        @media screen and (max-width: 350px) {
          left: 28%;
          width: 75px;
          height: 75px;
        }
      }

      form {
        margin-top: 60px;
        padding: 20px 80px;

        .login_items {
          margin-bottom: 20px;

          span {
            font-size: 0.9rem;
          }

          .login_input {
            margin-top: 10px;
            display: flex;

            .loginicon {
              width: 35px;
              height: 35px;
              background-color: $primary-color;
              padding: 1px;
              color: $white;
              @include allCenter;
              margin-top: 5px;
              border-radius: 5px 0px 0px 5px;

              svg {
                font-size: 1.2rem;
              }
            }

            .login_detail {
              display: flex;
              flex-direction: column;
              width: 100%;

              input {
                @include input(0px 10px);
                height: 35px;
                width: 100%;
                border-left: 0px;
              }
            }
          }
        }

        .login_btn {
          width: 100%;
          @include allCenter;
          margin-top: 20px;
          margin-bottom: 20px;

          Button {
            @include button(5px 70px, 20px);

            &:disabled {
              background-color: $light-gray-color;
            }

            .loader {
              width: 23px !important;
              height: 23px !important;
              padding: 1px 10px;
            }
          }
        }

        .login_footer {
          @include horizontallysapce;
          white-space: nowrap;

          a {
            text-decoration: none;
            font-size: 0.85rem;
            color: $primary-color;
          }

          label>span>svg {
            font-size: 1.8rem;
            color: $gray;
          }

          label>span {
            font-size: 0.8rem;
            color: $gray;
          }

          @media screen and (max-width: 450px) {
            a {
              font-size: 0.75rem;
            }

            label>span>svg {
              font-size: 1.5rem;
            }

            label>span {
              font-size: 0.7rem;
            }
          }

          @media screen and (max-width: 350px) {
            flex-direction: column-reverse;
            gap: 10px;
          }
        }

        @media screen and (max-width: 600px) {
          padding: 20px 40px;
        }
      }
    }

    @media screen and (max-width: 450px) {
      padding: 20px;
    }
  }
}