@import "./mixin.module.scss";
@import "./partials.scss";

.forgetpass {
  padding: 10px;
  display: flex;

  .forget_left {
    flex: 1;
    text-align: center;
    color: $white;
    @include allCenter;
    flex-direction: column;

    h1 {
      margin-bottom: 10px;
      font-weight: 900;
      text-transform: uppercase;
      font-size: 2.3rem;
    }

    h4 {
      font-weight: 200;
      color: #ddd;
      font-size: 1.3rem;
      line-height: 1.2;
    }
    @media screen and(max-width:800px) {
      padding: 20px;
    }
    @media screen and(max-width:350px) {
      h1 {
        font-size: 2rem;
      }
      h4 {
        font-size: 1.1rem;
      }
    }
  }

  .forget_right {
    flex: 3;
    background-color: $bg-light-gray;
    border-radius: 12px;
    margin-left: 50px;
    @include allCenter;
    flex-direction: column;
    padding: 30px 30px;

    .forget_top {
      text-align: center;
      font-size: 0.7rem;
      color: $gray;
      margin-bottom: 30px;
      margin-top: 40px;
      @media screen and(max-width:350px) {
          font-size: 0.6rem;
      }
    }

    .forget_bottom {
      background-color: $white;
      border-top: 5px solid $primary-color;
      box-shadow: 0px 5px 5px $gray;

      .forget_bottom_wrapper {
        padding: 20px 50px;
        width: 550px;
        @media screen and(max-width:1000px) {
          width: fit-content;
        }
        @media screen and(max-width:600px) {
          padding: 20px 20px;
        }
      }

      .forget_details {
        display: flex;
        align-items: center;
        border-bottom: 0.6px solid $gray;
        margin-bottom: 40px;
        padding: 5px 10px 40px 10px;

        span {
          width: 50px;
          height: 50px;
          @include allCenter;

          svg {
            color: $gray;
            font-size: 2.2rem;
          }
        }

        P {
          width: 70%;
        }
        @media screen and(max-width:470px) {
          P {
            width: 70%;
            font-size: 0.9rem;
          }
        }
      }

      .forget_main {
        .forget_input {
          display: flex;
          flex-direction: column;

          span {
            color: $black;
            font-size: 0.9rem;
            font-weight: 900;
            margin-bottom: 15px;
          }

          input {
            @include input(6px 10px);
            margin-bottom: 30px;
          }

          button {
            @include button(6px 10px, 5px);
            background-color: $success-color;
          }
        }

        .forget_footer {
          @include horizontallysapce;
          margin: 70px 0px 20px 0px;

          span {
            color: $black;
            font-weight: 500;
            font-size: 1rem;
            cursor: pointer;
          }

          button {
            @include button(6px 30px, 5px);
            color: $black;
            background-color: $white;
            box-shadow: 0px 2px 5px $gray;
          }
          @media screen and(max-width:600px) {
            span {
              font-size: 0.8rem;
            }
            button {
              padding: 6px 20px;
            }
          }
          @media screen and(max-width:470px) {
            flex-direction: column-reverse;
            gap: 12px;
          }
        }
      }
    }
    @media screen and(max-width:800px) {
      margin-left: 0px;
    }
    @media screen and(max-width:350px){
      padding: 30px 12px;
    }
  }
  @media screen and(max-width:800px) {
    flex-direction: column;
    gap: 30px;
  }
}