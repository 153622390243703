@import "./mixin.module.scss";
@import "./partials.scss";

.AddTask {
  width: 100%;
  .AddTask_Wrapper {
    padding: 15px 10px;
    .Task_title {
      width: 100%;
      @include horizontallysapce;
      margin-bottom: 10px;

      .Task_title_left {
        h1 {
          font-size: 2rem;
          font-weight: 600;
          margin-bottom: 10px;
          color: $primary-color;
        }

        h5 {
          font-size: 1rem;
          font-weight: 400;

          a {
            font-weight: 600;
            cursor: pointer;
          }
        }

        @media screen and (max-width: 400px) {
          h1 {
            font-size: 1.6rem;
          }

          h5 {
            font-size: 0.9rem;
          }
        }
      }
    }
    .taskTop {
      @include verticallycenter;
      gap: 30px;
      .taskTop_wrapper {
        width: 50%;
        position: relative;
        @include verticallycenter;
        input {
          position: relative;
          width: 100%;
          @include input(10px 6px);
          background-color: $white;
          border: 0.1px solid rgba(0, 0, 0, 0.225);
          box-shadow: -4.973px 0.523px 5.82px 0.18px rgba(2, 29, 59, 0.11);
          height: inherit;
          border-left: 6px solid $primary-color;
        }
        select {
          font-size: 0.9rem;
          padding: 10px 6px;
          border-left: 6px solid $primary-color;
        }
      }
      @media screen and (max-width: 600px) {
        flex-direction: column;
        gap: 6px;
      }
    }
    .taskDescriptionBox {
      background-color: $white;
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.325);
      border-radius: 3px;
      padding: 15px 10px;
      margin: 10px 0px;
      div {
        div {
          div {
            div {
              h2 {
                font-size: 1.2rem;
                color: #333;
              }
            }
          }
        }
      }
    }
    .taskBox {
      background-color: $white;
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.325);
      border-radius: 3px;
      padding: 15px 10px;
      margin: 10px 0px;
      .taskBox_Heading {
        margin: 10px 0px 5px;
        h2 {
          font-size: 1.1rem;
          color: #333;
        }
      }
      .task_DetailBoxs {
        @include verticallycenter;
        gap: 30px;
        .task_DetailBox {
          @include verticallycenter;
          width: 100%;
          h4 {
            @include verticallycenter;
            svg {
              margin: 0px 6px;
              color: #777;
            }
            margin-right: 20px;
            text-transform: capitalize;
            font-size: 0.9rem;
            font-weight: 600;
            white-space: nowrap;
            width: 35%;
          }
          select {
            width: 100%;
            background-color: $white !important;
            padding: 10px 2px !important;
            font-size: 0.9rem;
            height: inherit !important;
          }
          input{
            width: 100%;
            @include input(10px 6px);
            background-color: $white;
            border: 0.1px solid rgba(0, 0, 0, 0.225);
            height: inherit;
          }
          .react_Select {
            white-space: nowrap;
            width: 100%;
            box-shadow: -4.973px 0.523px 5.82px 0.18px rgba(2, 29, 59, 0.11);

            .selectItem {
              @include verticallycenter;
              span {
                @include allCenter;
                svg {
                  font-size: 1.2rem;
                  margin-right: 5px;
                }
              }
            }
          }
        
        }
        @media screen and (max-width: 600px) {
          flex-direction: column;
          gap: 6px;
        }
      }
    }
    .task_Button {
      width: 100%;
      background-color: $white;
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.325);
      @include allCenter;
      margin-bottom: 20px;
      .task_Button_Wrppaer {
        padding: 10px;
        display: flex;
        gap: 20px;
        button {
          @include button(10px 30px, 5px);
          letter-spacing: 3px;
          font-weight: 600;
          text-transform: capitalize;
          transition: 0.1s;
        }
        button:nth-child(1) {
          background: $primary-color;
          &:hover {
            background-color: $success-color;
          }
        }
        button:nth-child(2) {
          background: #888;
          &:hover {
            background-color: red;
          }
        }
      }
    }
  }
}
// Task Actual Time
.taskActualTime {
  div {
    div {
      div {
        width: 350px;
        padding: 12px;
      }
    }
  }
  .taskActualTime_heading {
    position: relative;
    text-transform: capitalize;
    text-align: center;
    font-size: 1.3rem;
    color: $primary-color;
    margin-bottom: 10px;
    // background-color: red;
    .closelogo {
      position: absolute;
      top: 16px;
      right: 10px;
      @include closeBtn;
    }
  }
  .taskActualTime_Input {
    overflow: hidden;
    input {
      width: 95%;
      @include input(5px);
    }
  }
  .taskActualTime_Action {
    button {
      @include button(8px 16px, 3px);
    }
  }
}
