@import "./mixin.module.scss";
@import "./partials.scss";

.project {
  width: 100%;
  max-height: 100%;
  background-color: $light-gray;

  .project_wrapper {
    padding: 10px;

    .project_name_container {
      border: 1px solid lightgray;
      padding: 10px;

      .sub_container {
        .heading_Box {
          padding: 10px 0;
        }

        .project_input {
          font-size: 1.2rem !important;
        }
      }
    }

    .desc_container {
      border: 1px solid lightgray;
      padding: 10px;
      margin: 5px 0;

      .heading_Box {
        padding: 10px 0;
      }

      textarea {
        width: 98%;
        height: 150px;
        border: 1px solid lightgray;
        border-radius: 5px;
        outline: none;
        font-size: 1.2rem;
        padding: 10px;
      }
    }

    .start_end_date_container {
      border: 1px solid lightgray;
      padding: 10px;
      margin: 10px 0;

      .date_container {
        padding: 10px 0;

        .calendar_div {
          width: 100%;

          margin-top: 10px;

          .calendar {
            border: none;
            height: 40px;
            display: block;
            width: 93%;

            margin: 5px 0px;
            color: grey;
            box-shadow: -4.973px 0.523px 5.82px 0.18px rgba(2, 29, 59, 0.11);
            border-width: 0.81px;
            border-color: rgb(189, 186, 186);
            border-style: solid;
            border-radius: 5px;
            background-color: rgb(255, 255, 255);
            text-indent: 10px;
            font-size: 1.2rem;

            &::placeholder {
              text-transform: capitalize;
            }

            &:focus {
              outline: none;
            }
          }
        }
      }
    }

    .assign_container {
      border: 1px solid lightgray;
      padding: 10px;
      margin: 10px 0;

      .sub_container {
        // .heading {
        //     margin: 10px 0;
        // }
        // @include verticallycenter;
        padding-right: 40px;

        .assign_div {
          padding: 10px 0;

          select {
            margin: 0 !important;
            background-color: white;
          }

          .multiple_Select_Box {
            font-size: 1.2rem !important;
          }
        }

        .button_div {
          @include verticallycenter;
          justify-content: center;

          .button_submit,
          .button_cancel {
            background-color: aqua;
            width: 100px;
            padding: 10px;
            margin: 10px;
            border-radius: 5px;
            border: 1px solid transparent;
            cursor: pointer;
          }

          .button_submit {
            background-color: $success-color;
            font-weight: 500;
            font-size: 0.9375rem;
            line-height: 1.75;
            letter-spacing: 0.02857em;
            text-transform: uppercase;
            min-width: 64 px;
            border-radius: 4px;
            color: white;
            text-align: center;

            &:disabled {
              background-color: $light-gray-color;
            }

            &:hover {
              background-color: $primary-color;
            }

            .loader {
              width: 23px !important;
              height: 23px !important;
              padding: 1px 5px;
            }
          }

          .button_cancel {
            background-color: $gray;
            font-weight: 500;
            font-size: 0.9375rem;
            line-height: 1.75;
            letter-spacing: 0.02857em;
            text-transform: uppercase;
            min-width: 64px;
            border-radius: 4px;
            text-align: center;
            color: white;

            &:hover {
              background-color: $btn;
            }
          }
        }
      }
    }
  }
}