@import "./mixin.module.scss";
@import "./partials.scss";

.employeeDescription {
  position: relative;
  width: 100%;
  min-height: 100vh;
  max-height: 100%;
  background-color: $light-gray;
  @include allCenter;
  .employeeDescription_Wrapper {
    width: 100%;
    padding: 10px;
    .employeeDescription_Box {
      width: 100%;
      background-color: $white;
      padding: 20px 30px;
      overflow: hidden;
      .employeeDescriptionBox_Top {
        position: relative;
        width: 100%;
        @include verticallycenter;
        margin-bottom: 40px;
        .employeeDescription_logo {
          position: relative;
          width: 120px;
          height: 120px;
          border-radius: 100%;
          overflow: hidden;
          box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.225);
          &:before {
            content: "";
            position: absolute;
            background-color: $primary-color;
            inset: -10px 30px;
            animation: animate 4s infinite linear;
          }
          @keyframes animate {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
          &:after {
            content: "";
            position: absolute;
            inset: 5px;
            border-radius: 100%;
            z-index: 1;
          }
          .employeeDescription_Image {
            position: absolute;
            border-radius: 100%;
            z-index: 5;
            inset: 2px;
            overflow: hidden;
            transition: 0.3s;
            @include allCenter;
            background-color: $primary-color;
            color: $white;
            box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.225);
            h1{
              text-transform: uppercase;
              letter-spacing: 2px;
            }
          }
          &:hover .employeeDescription_Image {
            transform: scale(1.2);
            cursor: pointer;
          }
        }
        .employeeDescription_Title {
          margin-left: 21px;
          h2 {
            font-size: 1.4rem;
            font-weight: 400;
            margin-bottom: 16px;
          }
          h4 {
            font-size: 1.1rem;
            font-weight: 600;
            margin-bottom: 6px;
            text-transform: uppercase;
            color: $primary-color;
            letter-spacing: 1px;
          }
          button {
            @include button(8px 16px, 3px);
            @include allCenter;
            animation: btn 0.5s ease;
            svg {
              margin-right: 6px;
              font-size: 1rem;
            }
          }
          @keyframes btn {
            0% {
              transform: scale(0);
            }
            50% {
              transform: scale(1.1);
            }
            100% {
              transform: scale(1);
            }
          }
        }
        .employeeDate {
          position: absolute;
          right: 60px;
          top: 40px;
          background-color: $primary-color;
          color: $white;
          padding: 10px;
          border-radius: 6px;
          animation: date 0.5s ease;
          h4 {
            font-size: 0.8rem;
          }
        }
        @keyframes date {
          0% {
            transform: translateY(-100px);
          }
          50% {
            transform: translateY(10px);
          }
          100% {
            transform: translateY(0px);
          }
        }
      }
      .employeeDescriptionBox_Bottom {
        width: 100%;
        .employeeDescriptionBox_detail_heading {
          margin: 16px 0px;

          h1 {
            font-size: 1.3rem;
            font-weight: 600;
            color: #444;
          }
        }
        .employeeDescriptionBox_Detail_Wrapper {
          width: 100%;
          display: flex;
          gap: 12px;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          .employeeDescriptionBox_Detail,
          .employeeDescriptionBox_Skill {
            width: 27%;
            padding: 12px;
            @include verticallycenter;
            .employeeDescriptionBox_Detail_Icon {
              margin-right: 10px;
              padding: 3px;
              svg {
                font-size: 1.4rem;
                color: #555;
              }
            }
            .employeeDescriptionBox_Detail_Item {
              width: 100%;
              span {
                font-size: 1rem;
                font-weight: 600;
                color: #666;
              }
              h4 {
                color: $primary-color;
                margin: 5px 0px;
                font-size: 0.9rem;
                font-weight: 600;
              }
              .employeeDescriptionBox_skills {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;
                gap: 12px;
                h4 {
                  margin: 5px 0px;
                  color: white;
                  font-size: 0.9rem;
                  text-transform: capitalize;
                  background-color: $primary-color;
                  padding: 6px 10px;
                  font-weight: 600;
                  border-radius: 3px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.employeeDescriptionBox_Skill{
  width: 100% !important;
  margin-right: 30px;
}