@import "./mixin.module.scss";
@import "./partials.scss";

.filtering {
    width: 100%;
    padding: 30px 0 20px 0;
    border-bottom: 1px solid lightgray;

    .filteringWrapper {
        @include verticallycenter;
        gap: 12px;

        .filteringByName {
            flex: 0.8;
        }

        .filteringBySelect {
            flex: 0.8;
        }

        .resetButton {
            flex: 0.2;
        }

        .filteringByDateRange {
            flex: 1.6;
            @include verticallycenter;
            z-index: 10;

            .calender {
                z-index: 111 !important;
            }
        }

        input {
            width: 90%;
            @include input(10px 6px);
            border: 0.1px solid rgba(0, 0, 0, 0.225);
            box-shadow: -4.973px 0.523px 5.82px 0.18px rgba(2, 29, 59, 0.11);
            background-color: $white;
        }

        select {
            background-color: $white  !important;
            padding: 10px 6px !important;
            font-size: 0.9rem;
            height: inherit !important;
        }

        Button {
            @include button(5px 17px, 17px);
            background-color: $success-color;
            font-size: 0.9rem;
            transition: 0.1s ease;
            font-family: GothamBook;
            font-weight: 600;
            margin: 0 20px;
        }

    }


}