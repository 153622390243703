@import "./mixin.module.scss";
@import "./partials.scss";

.employees {
  width: 100%;
  max-height: 100%;
  min-height: 100%;
  background-color: $light-gray;

  .employees_wrapper {
    padding: 10px;

    .employees_top {
      width: 100%;
      @include horizontallysapce;
      margin-bottom: 30px;

      .employees_left {
        h1 {
          font-size: 2rem;
          font-weight: 600;
          margin-bottom: 10px;
          color: $primary-color;
        }

        h5 {
          font-size: 1rem;
          font-weight: 400;

          a {
            font-weight: 600;
            cursor: pointer;
          }
        }

        @media screen and (max-width: 400px) {
          h1 {
            font-size: 1.6rem;
          }

          h5 {
            font-size: 0.9rem;
          }
        }
      }
    }

    .add_employees {
      background-color: $white;

      width: 100%;
      border-radius: 3x;
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.225);

      .add_employees_inputs {
        width: 100%;

        .employees_input_wrapper {
          padding: 40px 80px 0px 80px;
          display: flex;
          gap: 60px;

          div {
            width: 100%;

            label {
              color: $secondary-color;
              font-size: 1rem;
              font-weight: 600;
              margin-bottom: 3px;
              margin-left: 3px;

              span {
                color: $red;
                font-size: 1.1rem;
              }
            }

            input {
              font-size: 1rem;
            }
          }

          @media screen and (max-width: 650px) {
            flex-direction: column;
            gap: 26px;
            padding: 30px 30px 0px 30px;
          }
        }

        .employeesInput {
          padding: 30px 80px 0px 80px;

          @media screen and (max-width: 650px) {
            padding: 30px 30px 0px 30px;
          }
        }
      }

      .add_employees_btn {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 30px 0px;

        .loader {
          width: 23px !important;
          height: 23px !important;
          padding: 1px 40px;
        }

        Button {
          @include button(10px 20px, 5px);
          margin-right: 80px;

          &:disabled {
            background-color: $light-gray-color;
          }
        }
      }
    }
  }
}