@import "./mixin.module.scss";
@import "./partials.scss";

.employees {
  width: 100%;
  max-height: 100%;
  min-height: 100%;
  background-color: $light-gray;

  .employees_wrapper {
    padding: 10px;

    .employees_top {
      width: 100%;
      @include horizontallysapce;
      margin-bottom: 30px;

      .employees_left {
        h1 {
          font-size: 2rem;
          font-weight: 600;
          margin-bottom: 10px;
          color: $primary-color;
        }

        h5 {
          font-size: 1rem;
          font-weight: 400;

          a {
            font-weight: 600;
            cursor: pointer;
          }
        }

        @media screen and (max-width: 400px) {
          h1 {
            font-size: 1.6rem;
          }

          h5 {
            font-size: 0.9rem;
          }
        }
      }

      .employees_right {
        @include verticallycenter;
        gap: 10px;
        .DataChangeBtn {
          width: 36px;
          height: 36px;
          background-color: transparent;
          cursor: pointer;
          border: none;

          .bar {
            width: 5px;
            height: 4px;
            background-color: #333;
            margin: 3px auto;
            transition: 0.1s ease;
            box-shadow: 8px 0px 0px 0px #111, -8px 0px 0px 0px #111;
          }
        }
        .DataChangeBtnActive {
          .bar {
            box-shadow: none;
            width: 80%;
            height: 3px;
            margin: 4px auto;
            border-radius: 12px;
            transition: 0.1s ease;
          }
        }
        a {
          @include button(10px 20px, 30px);
          @include allCenter;
          font-weight: bolder;
          letter-spacing: 0.5px;

          span {
            margin-right: 5px;
          }
        }
     

        @media screen and (max-width: 400px) {
          a {
            padding: 6px 12px;
          }
        }
      }
      @media screen and (max-width: 450px) {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}