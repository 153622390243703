@import "./mixin.module.scss";
@import "./partials.scss";

.attendance {
    width: 100%;
    max-height: 100%;
    background-color: $light-gray;
  
    .attendance_wrapper {
      padding: 10px;
  
      .attendance_top {
        width: 100%;
        @include horizontallysapce;
        margin-bottom: 30px;
  
        .attendance_left {
          h1 {
            font-size: 2rem;
            font-weight: 600;
            margin-bottom: 10px;
            color: $primary-color;
          }
  
          h5 {
            font-size: 1rem;
            font-weight: 400;
  
            a {
              font-weight: 600;
              cursor: pointer;
            }
          }
  
          @media screen and (max-width: 400px) {
            h1 {
              font-size: 1.6rem;
            }
  
            h5 {
              font-size: 0.9rem;
            }
          }
        }
  
        .attendance_right {
          a {
            @include button(10px 20px, 30px);
            @include allCenter;
            font-weight: bolder;
            letter-spacing: 0.5px;
  
            span {
              margin-right: 5px;
            }
          }
  
          @media screen and (max-width: 400px) {
            a {
              padding: 6px 12px;
            }
          }
        }
      }
    }
  }