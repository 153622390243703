* {
  padding: 0px;
  margin: 0px;
}

@font-face {
  font-family: GothamBook;
  src: url("./assets/fonts/GothamBook.ttf");
}

body {
  font-family: GothamBook;
}
a {
  text-decoration: none;
  color: inherit;
}
/* arrow input none  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
