@import "./mixin.module.scss";
@import "./partials.scss";

.projectDescription {
  position: relative;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  background-color: $light-gray;
  @include allCenter;
  .projectDescriptionWrapper {
    width: 100%;
    padding: 10px;
    .projectDescriptionBox {
      width: 100%;
      height: 100%;
      background-color: $white;
      padding: 20px 30px;
      overflow: hidden;
      .projectDescriptionBox_Top {
        position: relative;
        width: 100%;
        @include verticallycenter;
        margin-bottom: 40px;

        .projectDescriptionBox_Title {
         
          h4 {
            font-size: 1.4rem;
            font-weight: 600;
            margin-bottom: 6px;
            text-transform: uppercase;
            color: $primary-color;
            letter-spacing: 1px;
          }
        }
        .projectStatus {
          position: absolute;
          right: 60px;
          top:0px;
          color: $white;
          padding: 10px;
          border-radius: 6px;
          animation: Status 0.5s ease;
          h4 {
            font-size: 0.9rem;
          }
        }
        @keyframes Status {
          0% {
            transform: translateY(-100px);
          }
          50% {
            transform: translateY(10px);
          }
          100% {
            transform: translateY(0px);
          }
        }
      }
      .projectDescriptionBox_Bottom {
        width: 100%;
        .projectDescriptionBox_Detail_heading {
          margin: 16px 0px;

          h1 {
            font-size: 1.3rem;
            font-weight: 600;
            color: #444;
          }
        }
        .projectDescriptionBox_Detail_Wrapper {
          width: 100%;
          display: flex;
          gap: 12px;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          .projectDescriptionBox_Detail,
          .projectDescriptionBox_Detail_Date {
            width: 100%;
            padding: 12px;
            @include verticallycenter;
            .projectDescriptionBox_Detail_Icon {
              margin-right: 10px;
              padding: 3px;
              svg {
                font-size: 1.4rem;
                color: #555;
              }
            }
            .projectDescriptionBox_Detail_Item {
              width: 100%;
              span {
                font-size: 1rem;
                font-weight: 600;
                color: #666;
              }
              h4 {
                color: $primary-color;
                margin: 5px 0px;
                font-size: 0.9rem;
                font-weight: 600;
              }
              .projectDescriptionBox_skills {
                display: flex;
                align-items: center;
                gap: 16px;
                h4 {
                  margin: 5px 0px;
                  color: white;
                  font-size: 0.9rem;
                  text-transform: capitalize;
                  background-color: $primary-color;
                  padding: 6px 10px;
                  font-weight: 600;
                  border-radius: 3px;
                }
              }
              .employeeDescriptionBox_Assigne {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;
                gap: 12px;
                h4 {
                  margin: 5px 0px;
                  color: white;
                  font-size: 0.9rem;
                  text-transform: capitalize;
                  background-color: $primary-color;
                  padding: 6px 10px;
                  font-weight: 600;
                  border-radius: 3px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.projectDescriptionBox_Detail_Date {
  width: 45% !important;
}
