@import "./mixin.module.scss";
@import "./partials.scss";

.swiperbtn {
  position: absolute;
  top: 80px;
  right: 100px;
  background-color: $primary-color;
  width: 150px;
  padding: 8px 12px;
  border-radius: 30px;
  transition: 0.3s linear;

  ul {
    @include horizontallysapce;
    list-style: none;

    li {
      color: $white;
      font-weight: 600;

      .registerText {
        color: $black;
        position: absolute;
        left: 10px;
        cursor: pointer;
      }
      .loginText {
        color: $black;
        position: absolute;
        right: 20px;
        cursor: pointer;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    top: 20px;
  }
}

.activeClassName {
  position: relative;
  border-radius: 30px;
  padding: 5px 10px;
  background-color: $white;
  color: $primary-color;
  transition: 0.3s linear;
  margin-left: -10px;
}
