@import "./mixin.module.scss";
@import "./partials.scss";

.ticket_bottom {
  position: relative;
  width: 100%;
  background-color: $white;
  box-shadow: 0px 0px 5px 1px rgba(85, 5, 0, 0.3);
  margin-top: 10px;

  .tableassign {
    @include verticallycenter;
    margin: 10px 0px;
    .assignIcon {
      position: relative;
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border-radius: 100%;
      background-color: $primary-color;
      @include allCenter;
      color: $white;
      font-size: 0.7rem;
      text-transform: capitalize;
    }
  }

  .statusSelect {
    @include allCenter;
    min-width: 150px;
    white-space: nowrap;
    .selectItem {
      @include verticallycenter;
      span {
        @include allCenter;
        svg {
          font-size: 1.2rem;
          margin-right: 5px;
        }
      }
    }
  }

  .tablePriorityNoraml,
  .tablePriorityHigh,
  .tablePriorityLow,
  .statusBlock,
  .statusTesting,
  .statusDone,
  .statusClose,
  .statusInprogress,
  .statusTodo {
    text-transform: capitalize;
    padding: 6px 8px;
    border-radius: 3px;
    color: $white;
    font-weight: 600;
    white-space: nowrap;
  }
  // Priority
  .tablePriorityNoraml {
    background-color: $orange;
    color: brown;
  }
  .tablePriorityHigh {
    background-color: $red;
  }
  .tablePriorityLow {
    background-color: $green;
  }
// Status
  .statusBlock {
    background-color: #111;
  }
  .statusTesting {
    background-color: #ff05ff;
  }
  .statusDone {
    background-color: #283686;
  }
  .statusClose {
    background-color: #ff2018;
  }
  .statusInprogress {
    background-color: #2bb756;
  }
  .statusTodo {
    background-color: #ffa12b;
  }

  .tableIcon {
    color: $primary-color;
    @include verticallycenter;
    gap: 10px;
    transition: 0.1s ease;
    svg {
      font-size: 1.7rem;
    }
    svg:nth-child(1) {
      &:hover {
        color: orange;
        transform: scale(1.1);
      }
    }

    svg:nth-child(2) {
      &:hover {
        color: $success-color;
        transform: scale(1.1);
      }
    }
  }
}
