@import "./mixin.module.scss";
@import "./partials.scss";

.task_Table {
  position: relative;
  width: 100%;
  background-color: $white;
  box-shadow: 0px 0px 5px 1px rgba(85, 5, 0, 0.3);
  .tableHeadeer {
    @include verticallycenter;
    gap: 30px;
    .tableSelect_wrapper {
      @include verticallycenter;
      gap: 12px;
      span {
        white-space: nowrap;
        font-size: 1rem;
        color: $primary-color;
        font-weight: 600;
      }
      .tableSelect {
        box-shadow: none !important;
        border: none;
        outline: none;
        border: 0.5px solid #aaa;
        height: auto;
        padding: 8px 12px;
        font-size: 0.9rem;
        border-radius: 3px;
        font-family: GothamBook;
        width: 200px;
      }
    }

    .tableSearch {
      @include input(8px 12px);
      width: 250px;
      border: 0.5px solid #aaa;
    }
  }

  .tableIcon {
    color: $primary-color;
    @include verticallycenter;
    gap: 10px;
    transition: 0.1s ease;
    svg {
      font-size: 1.7rem;
    }
    svg:nth-child(1) {
      &:hover {
        color: $orange-color;
        transform: scale(1.1);
      }
    }
    svg:nth-child(2) {
      display: none;
      &:hover {
        color: $red;
      }
    }
    svg:nth-child(3) {
      &:hover {
        color: $success-color;
        transform: scale(1.1);
      }
    }
  }
}

.tableStatusApproved,
.tableStatusPending,
.tableStatusLeft {
  color: white;
  font-weight: 600;
  padding: 5px;
  border-radius: 4px;
}
.tableStatusApproved {
  background-color: $green;
}
.tableStatusPending {
  color: brown;
  background-color: $orange;
}
.tableStatusLeft {
  background-color: red;
}
